import React from "react";
import Footer from "../components/footer/Footer";
import * as styles from "./mentionslegales.module.scss";
import logo from "../assets/images/logoImageWhite.svg"
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const MentionsLegalesPage = () => {
    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>KOLABS - Révélateurs de talents</title>
                <html lang="fr" prefix="og: https://kolabs-web-site.netlify.app/"></html>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
                <meta name="robots" content="none" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.headerContent}>
                    <img className={styles.logo} src={logo} alt="logo kolabs" />
                    <Link className={styles.link} to="/">Accueil</Link>
                </div>
            </header>
            <main className={styles.main}>
                <h1>Mentions légales</h1>
                <br />
                Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet <a href="http://www.kolabs.fr">www.kolabs.fr</a> sont :

                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Editeur du Site : </b></span></p>
                KOLABS
                <br />
                Numéro de SIRET :  452724370
                <br />
                Responsable editorial : KOLABS
                <br />
                Adresse : 26 rue de la Bienfaisance, 75008 Paris
                <br />
                Téléphone : +33 9 75 90 25 16
                <br />
                Email : hello@kolabs.fr
                <br />
                Site Web : <a href="http://www.kolabs.fr">www.kolabs.fr</a>
                <br />
                <p style={{ color: "#b51a00;" }}><b><span style={{ color: "rgb(0, 0, 0);" }}>Hébergement :</span> </b></p>
                Hébergeur : OVH
                <br />
                Adresse : 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1
                <br />
                Site Web :  <a href="https://www.ovhcloud.com/fr/">https://www.ovhcloud.com/fr/</a>
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Développement</b><b> : </b></span></p>
                développeur du site : KOLABS
                <br />
                Adresse : 26 rue de la Bienfaisance, 75008 Paris
                <br />
                Site Web : <a href="http://www.kolabs.fr">www.kolabs.fr</a>
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Conditions d’utilisation : </b></span></p>
                Ce site (<a href="https://www.kolabs.fr">www.kolabs.fr</a>) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, KOLABS Chrome, etc…
                <br />
                <span style={{ color: "#323333;" }}>KOLABS editeur du site<b> </b></span>met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de , et signaler toutes modifications du site qu'il jugerait utile. n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.

                <b>Cookies</b> : Le site <a href="http://www.kolabs.fr">www.kolabs.fr</a> peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.

                <b>Liens hypertextes :</b> Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. KOLABS editeur du site ne dispose d'aucun moyen pour contrôler les sites en connexion avec ses sites internet. ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation.

                Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien en direction de ce site sans l'autorisation expresse et préalable de KOLABS editeur du site.

                Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de KOLABS editeur du site, il lui appartiendra d'adresser un email accessible sur le site afin de formuler sa demande de mise en place d'un hyperlien. KOLABS editeur du site se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Services fournis : </b></span></p>
                <p style={{ color: "#323333;" }}>L'ensemble des activités de la société ainsi que ses informations sont présentés sur notre site <a href="http://www.kolabs.fr">www.kolabs.fr</a>.</p>
                <p style={{ color: "#323333;" }}>KOLABS editeur du site s’efforce de fournir sur le site www.kolabs.fr des informations aussi précises que possible. les renseignements figurant sur le site <a href="http://www.kolabs.fr">www.kolabs.fr</a> ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site www.kolabs.fr<span style={{ color: "#000000;" }}><b> </b></span>sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.  </p>
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Limitation contractuelles sur les données : </b></span></p>
                Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse hello@kolabs.fr, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).

                Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement. <span style={{ color: "#323333;" }}>De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour</span>

                Les liens hypertextes mis en place dans le cadre du présent site internet en direction d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de KOLABS editeur du site.
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Propriété intellectuelle :</b></span></p>
                Tout le contenu du présent sur le site <a href="http://www.kolabs.fr">www.kolabs.fr</a>, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.

                Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de KOLABS editeur du site. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Litiges : </b></span></p>
                Les présentes conditions du site <a href="http://www.kolabs.fr">www.kolabs.fr</a> sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.
                <br />
                <p style={{ color: "#b51a00;" }}><span style={{ color: "rgb(0, 0, 0);" }}><b>Données personnelles :</b></span></p>
                Ce site www.kolabs.fr ne collecte pas vos données personnelles.
            </main>
            <Footer />
        </div>
    )

}
export default MentionsLegalesPage;